import { createSlice } from "@reduxjs/toolkit";
import { e } from "mathjs";
import moment from "moment";

export const leaveSlice = createSlice({
  name: "leave",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    rowdata: {},
    leaveViewModel: false,
    leaveAddModel: false,
    leaveList: [],
    leaveData: {},
    leaveParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    CheckLeaveApprove: false,
    RejectMode: false,
    leaveRejectData: {},
    filterStatus: false,
    leaveBalance: 0,
    userLeaveBal: 0,
    NoOfDayOption: [],
    addApprovedModel: false,
    addApprovedData: {},
    selectedRowKeys: [],
    idStatus: {}
  },
  reducers: {
    getLeaveList: (state, action) => {
      if (state.leaveParams?.page == 1) {
        return {
          ...state,
          loading: true,
          leaveList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    leaveListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.leaveParams.page_size
      );
      var idStatus  = {}
      action.payload.response.results.map((item) => {
        idStatus[item.id] = item.leave_status
      })
      return {
        ...state,
        listCount: action.payload.response.count,
        // leaveList: [
        //   ...state.leaveList,
        //   ...action.payload.response.results,
        // ],
        idStatus: idStatus,
        leaveList: action.payload.response.results,
        loading: false,
        leaveParams: {
          ...state.leaveParams,
          no_of_pages: noofpages,
        },
      };
    },
    setLeaveParams: (state, action) => {
      return {
        ...state,
        leaveParams: action.payload,
      };
    },
    leaveDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    CheckLeaveApprove: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    LeaveApproveCheckSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        CheckLeaveApprove: action.payload,
      };
    },
    getLeaveById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    getLeaveByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        // leaveAddModel: true,
        leaveData: {
          ...action.payload.response,
          id: action.payload.response?.id,
          requested_date: moment(action.payload.response?.requested_date),
          from_date: moment(action.payload.response?.from_date),
          to_date: moment(action.payload.response?.to_date),
          leave_reason_id: action.payload.response.leave_reason?.id,
          halfday_on: action.payload.response.halfday_on,
        },
        leaveAddModel: action.payload.type == "update" ? true : false,
        leaveViewModel: action.payload.type == "view" ? true : false,
      };
    },
    
    leaveRequestAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    isLeaveViewModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          leaveViewModel: action.payload,
        };
      } else {
        return {
          ...state,
          leaveData: {},
          leaveViewModel: action.payload,
        };
      }
    },
    isLeaveAddModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          leaveAddModel: action.payload,
        };
      } else {
        return {
          ...state,
          leaveAddModel: action.payload,
          leaveData: {},
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        leaveParams: {
          ...state.leaveParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    InputChangeValue: (state, action) => {
      if (action.payload.key == "from_date") {
        return {
          ...state,
          leaveData: {
            ...state.leaveData,
            [action.payload.key]: action.payload.value,
            to_date: null,
            no_of_days: null,
          },
        };
      } else if (action.payload.key == "to_date") {
        return {
          ...state,
          leaveData: {
            ...state.leaveData,
            [action.payload.key]: action.payload.value,
            no_of_days: action.payload.value == "" ? null : action.payload.value.diff(state.leaveData.from_date, "days") + 1,
          },
          NoOfDayOption: action.payload.value == "" ? [] : [action.payload.value.diff(state.leaveData.from_date, "days") + 1, action.payload.value.diff(state.leaveData.from_date, "days") + 0.5 ],
        };
      } else {
        return {
          ...state,
          leaveData: {
            ...state.leaveData,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },

    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        leaveRejectData: {},
      };
    },
    rejectModelInputChangeValue: (state, action) => {
      return {
        ...state,
        leaveRejectData: {
          ...state.leaveRejectData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearLeaveList: (state, action) => {
      return {
        ...state,
        loading: true,
        leaveList: [],
        leaveParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        filterStatus: false,
        selectedRowKeys: []
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    SelectChangeValue: (state, action) => {
      return {
        ...state,
        leaveData: {
          ...state.leaveData,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name
          },
        },
      };
    },
    getLeaveBalance: (state, action) => {
      return {
        ...state,
        loading: true,
        leaveBalance: 0,
      }
    },
    leaveBalanceSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        leaveBalance: action.payload.leave_balance.toFixed(3),
      }
    },
    getUserByIdLeaveBal: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    userByIdLeaveBalSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        userLeaveBal: action.payload.leave_balance,
      };
    },
    isLeaveAddAprrovedModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          addApprovedModel: action.payload,
        };
      } else {
        return {
          ...state,
          addApprovedModel: action.payload,
          addApprovedData: {},
        };
      }
    },
    ApprovedInputChangeValue: (state, action) => {
      if (action.payload.key == "from_date") {
        return {
          ...state,
          addApprovedData: {
            ...state.addApprovedData,
            [action.payload.key]: action.payload.value,
            to_date: null,
            no_of_days: null,
          },
        };
      } else if (action.payload.key == "to_date") {
        return {
          ...state,
          addApprovedData: {
            ...state.addApprovedData,
            [action.payload.key]: action.payload.value,
            no_of_days: action.payload.value == "" ? null : action.payload.value.diff(state.addApprovedData.from_date, "days") + 1,
          },
          NoOfDayOption: action.payload.value == "" ? [] : [action.payload.value.diff(state.addApprovedData.from_date, "days") + 1, action.payload.value.diff(state.addApprovedData.from_date, "days") + 0.5 ],
        };
      } else {
        return {
          ...state,
          addApprovedData: {
            ...state.addApprovedData,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    SelectApprovedChangeValue: (state, action) => {
      return {
        ...state,
        addApprovedData: {
          ...state.addApprovedData,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name
          },
        },
      };
    },
    leaveApprovedAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    setSelectedRowKeys: (state, action) => {
      return{
        ...state,
        loading: false,
        selectedRowKeys: action.payload,
      }
    },
    selectRow: (state, action) => {
      console.log("selectRow", action.payload)
      const updatedSelectedRowKeys = [...state.selectedRowKeys];
      if (updatedSelectedRowKeys.includes(state.selectedRowKeys.key)) {
        const index = updatedSelectedRowKeys.indexOf(state.selectedRowKeys.key);
        updatedSelectedRowKeys.splice(index, 1);
      }else{
        updatedSelectedRowKeys.push(state.selectedRowKeys.key);
      }
      return {
        ...state,
        loading: false,
        selectedRowKeys: updatedSelectedRowKeys,
      }
    },


    // Leave List & Grid approvals
    LeaveApprove: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    LeaveApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        // leaveViewModel: false,
        // RejectMode: false,
        selectedRowKeys: [],
      };
    },
    // Leave view approvals
    LeaveViewApprove: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    LeaveViewApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        leaveViewModel: false,
        RejectMode: false,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getLeaveList,
  leaveListSuccessful,
  setLeaveParams,
  getLeaveById,
  getLeaveByIdSuccessful,
  leaveDelete,
  isLeaveViewModel,
  isLeaveAddModel,
  FilterInputChangeValue,
  InputChangeValue,
  leaveRequestAdd,
  CheckLeaveApprove,
  LeaveApproveCheckSuccessful,
  LeaveApprove,
  LeaveApproveSuccessful,
  isRejectModelVisible,
  rejectModelInputChangeValue,
  isDrawerVisible,
  clearLeaveList,
  checkFilterStatus,
  SelectChangeValue,
  getLeaveBalance,
  leaveBalanceSuccessful,
  getUserByIdLeaveBal,
  userByIdLeaveBalSuccessful,
  isLeaveAddAprrovedModel,
  ApprovedInputChangeValue,
  SelectApprovedChangeValue,
  leaveApprovedAdd,
  setSelectedRowKeys,
  selectRow,
  LeaveViewApprove,
  LeaveViewApproveSuccessful,
  apiError,
} = leaveSlice.actions;

export default leaveSlice.reducer;
