import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Drawer,
  Space,
  Dropdown,
  Divider,
  Avatar,
  List,
} from "antd";
import { Notification, TickSquare, CloseSquare, Danger } from "react-iconly";
import {
  isDrawerVisible,
  getNotificationList,
  SetNotificationParams,
} from "../../../store/notifications/notificationSlice";
import { Link } from "react-router-dom";
import HeaderNotifications from "./HeaderNotifications";

function allNotifications(props) {
  const [form] = Form.useForm();

  // useEffect(() => {
  //   props.getNotificationList({  ...props.notificationParams, page: 1, search: "", page_size: 10 });
  // }, []);

  const onLoadMore = () => {
    props.getNotificationList({
      ...props.notificationParams,
      page: props.notificationParams.page + 1,
      search: "",
      page_size: 10,
    });
    props.SetNotificationParams({
      ...props.notificationParams,
      page: props.notificationParams.page + 1,
      search: "",
      page_size: 10,
    });
  };

  const loadMore =
    props.notificationParams.page < props.notificationParams.no_of_pages &&
    !props.loading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}>
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;

  useEffect(() => {
    form.setFieldsValue({});
  }, [props.notificationParams]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Drawer
          title="All Notifications"
          width={320}
          className="hp-drawer-mobile"
          onClose={() => {
            props.isDrawerVisible(false);
          }}
          placement="right"
          open={props.drawer}
          bodyStyle={{
            paddingBottom: 10,
          }}>
          <List
            className=" hp-demo-loadmore-list "
            loading={props.loading}
            itemLayout="horizontal"
            loadMore={loadMore}
            dataSource={props.notificationList}
            renderItem={(data) => (
              <>
                <Link
                  to={
                    data.type == "onsite_request" ||
                    data.type == "onsite_request_approve"
                      ? "/pages/onSite"
                      : data.type == "late"
                      ? "/pages/attendance"
                      : data.type == "late_request" ||
                        data.type == "late_request_approve"
                      ? "/pages/lateRequest"
                      : data.type == "report_late"
                      ? "/pages/late"
                      : data.type == "leave_request" ||
                        data.type == "leave_request_approve"
                      ? "/pages/leave"
                      : data.type == "task_created" ||
                        data.type == "task_status_created" ||
                        data.type == "task_status_updated"
                      ? "/pages/task_management"
                      : ""
                  }
                  onClick={() => {
                    props.isDrawerVisible(false);
                  }}
                  className="hp-mb-10 ">
                  <Row
                    align="middle"
                    className="hp-cursor-pointer hp-border-radius hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-py-8 hp-px-10"
                    style={{ marginLeft: -10, marginRight: -10 }}>
                    <Col span={4} md={4}>
                      <Avatar
                        size={38}
                        icon={
                          <TickSquare
                            size={16}
                            className="hp-text-color-success-1"
                          />
                        }
                        className="hp-d-flex-center-full hp-bg-success-4"
                      />
                    </Col>

                    <Col span={20} md={20}>
                      <span className="hp-d-block hp-w-100 hp-mb-4 hp-font-weight-500 hp-p1-body">
                        {data.type}
                      </span>

                      <span className="hp-d-block hp-badge-text hp-font-weight-400 hp-text-color-black-60 hp-text-color-dark-40">
                        {data.subject}
                      </span>
                    </Col>
                  </Row>
                </Link>
                <Divider className="hp-my-4" />
              </>
            )}
          />

          {/* {props.notificationList.map((item, key) => {
          return (
            <>
              <Row
                align="middle"
                className="hp-cursor-pointer hp-border-radius hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-py-8 hp-px-10"
                style={{ marginLeft: -10, marginRight: -10 }}
                key={key}
              >
                <Col span={4} md={4}>
                  <Avatar
                    size={38}
                    icon={
                      <TickSquare
                        size={16}
                        className="hp-text-color-success-1"
                      />
                    }
                    className="hp-d-flex-center-full hp-bg-success-4"
                  />
                </Col>

                <Col span={20} md={20}>
                  <span className="hp-d-block hp-w-100 hp-mb-4 hp-font-weight-500 hp-p1-body">
                    {item.type}
                  </span>

                  <span className="hp-d-block hp-badge-text hp-font-weight-400 hp-text-color-black-60 hp-text-color-dark-40">
                    {item.subject}
                  </span>
                </Col>
              </Row>

              <Divider className="hp-my-4" />
            </>
          );
        })} */}
        </Drawer>
      </Col>
    </Row>
  );
}

function mapStateToProps({ notification }) {
  return {
    drawer: notification.drawer,
    notificationParams: notification.notificationParams,
    listCount: notification.listCount,
    notificationList: notification.notificationList,
  };
}

export default connect(mapStateToProps, {
  isDrawerVisible,
  getNotificationList,
  SetNotificationParams,
})(allNotifications);
