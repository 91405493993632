import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const locationSlice = createSlice({
  name: "onSiteLocation",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    onSitelocationList: [],
    model: false,
    drawer: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    onSiteLocationParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    filterStatus: false,
  },
  reducers: {
    getOnSiteLocation: (state, action) => {
      if (state.onSiteLocationParams?.page == 1) {
        return {
          ...state,
          loading: true,
          onSitelocationList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    onSiteLocationListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.onSiteLocationParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.onSitelocationList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.onSiteLocationParams?.page_size
      );
      return {
        ...state,
        onSitelocationList: list,
        listCount: response.count,
        loading: false,
        onSiteLocationParams: {
          ...state.onSiteLocationParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetOnSiteLocationParams: (state, action) => {
      return {
        ...state,
        onSiteLocationParams: action.payload,
      };
    },

    onSiteLocationAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    onSiteLocationAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    getOnSiteLocationByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getOnSiteLocationByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
          state_id: action.payload.state.id,
          city_id: action.payload.city.id,
        },
      };
    },
    onSiteLocationDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      if(action.payload.key == "state_id"){
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            city_id: null
          },
        };
      }else{
     return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
          },
      }}
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      }
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      if(action.payload.key == "state"){
        return {
          ...state,
          onSiteLocationParams: {
            ...state.onSiteLocationParams,
            [action.payload.key]: action.payload.value,
            city: null,
          },
        };
      }else{
        return {
          ...state,
          onSiteLocationParams: {
            ...state.onSiteLocationParams,
            [action.payload.key]: action.payload.value,
          },
        };
      }
      
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getOnSiteLocation,
  onSiteLocationListSuccessful,
  SetOnSiteLocationParams,
  onSiteLocationAdd,
  onSiteLocationAddSuccessful,
  getOnSiteLocationByID,
  getOnSiteLocationByIDSuccessful,
  onSiteLocationDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  checkFilterStatus,
} = locationSlice.actions;

export default locationSlice.reducer;
