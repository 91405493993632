import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getLateList,
  LateListSuccessful,
  getLateByIdSuccessful,
  isLateReasonModel,
  LateApproveCheckSuccessful,
  LateApproveSuccessful,
} from "./lateRequestSlice";
import { getAttendanceList } from "../attendance/attendanceSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import { select } from "redux-saga/effects";
import { evaluate } from "mathjs";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.
function* LeaveListGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/attendance/late_requests/",
      params
    );
    yield put(LateListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* AddLateRequest({ payload }) {
  let lateRequestParams = yield select((state) => state.lateRequests.lateParams)
  try {
    if (
      payload.obj.id == 0 ||
      payload.obj.id == undefined ||
      payload.obj.id == null
    ) {
      const response = yield call(
        postAdd,
        "/attendance/late_requests/",
        payload.obj
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Late Submitted Successfully.!
              </p>
            </div>
          ),
        });
        if (payload.screen === "attendance") {
          yield put(getAttendanceList({ page: 1, search: "", page_size: 10 }));
        } else {
          yield put(getLateList({ page: 1, search: "", page_size: 10 }));
        }
        yield put(
          isLateReasonModel({
            row: {},
            model: false,
            // screen: payload.LateScreen,
          })
        );
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to submit Late. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/attendance/late_requests/update/" + payload.obj.id,
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your late Edited Successfully.!
              </p>
            </div>
          ),
        });
        if (payload.screen === "attendance") {
          yield put(getAttendanceList({ page: 1, search: "", page_size: 10 }));
        } else {
          yield put(getLateList(lateRequestParams));
        }
        yield put(
          isLateReasonModel({
            row: {},
            model: false,
            // screen: payload.LateScreen,
          })
        );
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit late. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
    // yield put(addPurchaseSuccessful(null));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    // yield put(addPurchaseSuccessful(null));
    yield put(apiError(error));
  }
}
function* 
LateById({ payload }) {
  try {
    const response = yield call(
      getList,
      "/attendance/late_requests/" + payload.row.id,
      {}
    );
      yield put(getLateByIdSuccessful({ response: response.data, type: payload.type }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* LateApproveCheck({ payload }) {
  try {
    const response = yield call(
      getList,
      "/attendance/late_requests/check_approve/" + payload.id,
      {}
    );
    if (response.status === 204) {
      yield put(LateApproveCheckSuccessful(true));
    } else {
      yield put(LateApproveCheckSuccessful(false));
    }
    // console.log("LeaveApproveCheck", response);
    // yield put(LateApproveCheckSuccessful({ response, type: payload.type }));
  } catch (error) {
    yield put(apiError(error));
    yield put(LateApproveCheckSuccessful(false));
  }
}

function* LeaveDelete({ payload }) {
  let lateRequestParams = yield select((state) => state.lateRequests.lateParams)
  try {
    const response = yield call(postDelete, "/attendance/leave/" + payload.id);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Leave has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getLateList(lateRequestParams));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Leave has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* LateApprovePost({ payload }) {
  let lateRequestParams = yield select((state) => state.lateRequests.lateParams)
  try {
    const response = yield call(
      postEdit,
      "/attendance/late_requests/approve/" + payload.id,
      payload.approveObj
    );
    if (response.status === 200) {
      if (payload.approveObj.late_status == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                 Late Approved Successfully.!
              </p>
            </div>
          ),
        });
      }else if(payload.approveObj.late_status == 3) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                 Late has been Rejected Successfully!
              </p>
            </div>
          ),
        });
      }
      yield put(LateApproveSuccessful(response));
      yield put(
        getLateList(lateRequestParams)
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Approve Leave. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetLateList() {
  yield takeEvery("lateRequests/getLateList", LeaveListGet);
}
export function* watchLateRequestAdd() {
  yield takeEvery("lateRequests/addLateRequests", AddLateRequest);
}
export function* watchGetLateById() {
  yield takeEvery("lateRequests/getLateById", LateById);
}
export function* watchCheckLateApprove() {
  yield takeEvery("lateRequests/CheckLateApprove", LateApproveCheck);
}
export function* watchLeaveDelete() {
  yield takeEvery("lateRequests/leaveDelete", LeaveDelete);
}

export function* watchLateApprove() {
  yield takeEvery("lateRequests/LateApprove", LateApprovePost);
}

function* LateRequestSaga() {
  yield all([
    fork(watchGetLateList),
    fork(watchLateRequestAdd),
    fork(watchGetLateById),
    fork(watchLeaveDelete),
    fork(watchCheckLateApprove),
    fork(watchLateApprove),
  ]);
}

export default LateRequestSaga;
