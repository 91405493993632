import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const onSiteSlice = createSlice({
  name: "onSite",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    rowdata: {},
    onSiteViewModel: false,
    onSiteAddModel: false,
    onSiteList: [],
    onSiteData: {},
    onSiteParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    CheckOnSiteApprove: false,
    OnSiteRejectData: {},
    RejectMode: false,
    filterStatus: false,
    addApprovedModel: false,
    addApprovedData: {},
    selectedRowKeys: [],
    idStatus: {},
  },
  reducers: {
    getOnSiteList: (state, action) => {
      if (state.onSiteParams?.page == 1) {
        return {
          ...state,
          loading: true,
          onSiteList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    onSiteListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.onSiteParams.page_size
      );
      var idStatus  = {}
      action.payload.response.results.map((item) => {
        idStatus[item.id] = item.onsite_status
      })
      return {
        ...state,
        listCount: action.payload.response.count,
        // leaveList: [
        //   ...state.leaveList,
        //   ...action.payload.response.results,
        // ],
        idStatus: idStatus,
        onSiteList: action.payload.response.results,
        loading: false,
        onSiteParams: {
          ...state.onSiteParams,
          no_of_pages: noofpages,
        },
      };
    },
    setOnSiteParams: (state, action) => {
      return {
        ...state,
        onSiteParams: action.payload,
      };
    },
    onSiteDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    CheckOnSiteApprove: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    OnSiteApproveCheckSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        CheckOnSiteApprove: action.payload,
      };
    },
   
    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        OnSiteRejectData: {},
      };
    },
    rejectModelInputChangeValue: (state, action) => {
      return {
        ...state,
        OnSiteRejectData: {
          ...state.OnSiteRejectData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getOnSiteById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getOnSiteByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        onSiteData: {
          ...action.payload.response,
          id: action.payload.response?.id,
          from_date: moment(action.payload.response?.from_date),
          to_date: moment(action.payload.response?.to_date),
          onsite_location_id: action.payload.response.onsite_location?.id,
          type: action.payload.response.type,
        },
        onSiteAddModel: action.payload.type == "update" ? true : false,
        onSiteViewModel: action.payload.type == "view" ? true : false,
      };
    },

    onSiteAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    isOnSiteViewModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          onSiteViewModel: action.payload,
        };
      } else {
        return {
          ...state,
          onSiteData: {},
          onSiteViewModel: action.payload,
        };
      }
    },
    isOnSiteAddModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          onSiteAddModel: action.payload,
        };
      } else {
        return {
          ...state,
          onSiteAddModel: action.payload,
          onSiteData: {},
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        onSiteParams: {
          ...state.onSiteParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        onSiteData: {
          ...state.onSiteData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    SelectChangeValue: (state, action) => {
      return {
        ...state,
        onSiteData: {
          ...state.onSiteData,
          [action.payload.key]: {
           id: action.payload.value,
          name: action.payload.name},
        },
      };
    },

    clearOnsiteList: (state, action) => {
      return {
        ...state,
        loading: true,
        onSiteList: [],
        onSiteParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        filterStatus: false,
        selectedRowKeys: []
      };
    },

    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    IsAddApprovedModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          addApprovedModel: action.payload,
        };
      } else {
        return {
          ...state,
          addApprovedModel: action.payload,
          addApprovedData: {},
        };
      }
    },
    ApprovedInputChangeValue: (state, action) => {
      return {
        ...state,
        addApprovedData: {
          ...state.addApprovedData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    SelectApprovedChangeValue: (state, action) => {
      return {
        ...state,
        addApprovedData: {
          ...state.addApprovedData,
          [action.payload.key]: {
           id: action.payload.value,
          name: action.payload.name},
        },
      };
    },
    onSiteApprovedAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    setSelectedRowKeys: (state, action) => {
      return{
        ...state,
        loading: false,
        selectedRowKeys: action.payload,
      }
    },
    selectRow: (state, action) => {
      console.log("selectRow", action.payload)
      const updatedSelectedRowKeys = [...state.selectedRowKeys];
      if (updatedSelectedRowKeys.includes(state.selectedRowKeys.key)) {
        const index = updatedSelectedRowKeys.indexOf(state.selectedRowKeys.key);
        updatedSelectedRowKeys.splice(index, 1);
      }else{
        updatedSelectedRowKeys.push(state.selectedRowKeys.key);
      }
      return {
        ...state,
        loading: false,
        selectedRowKeys: updatedSelectedRowKeys,
      }
    },


    // Onsite view  approvals

    OnSiteViewApprove: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    OnSiteViewApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        onSiteViewModel: false,
        RejectMode: false,
      };
    },

     // Onsite List & Grid approvals
    onSiteApprove: (state, action) => {
      console.log("onSiteApprove", action.payload)
      return {
        ...state,
        loading: true,
      };
    },
    onSiteApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        // leaveViewModel: false,
        // RejectMode: false,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getOnSiteList,
  onSiteListSuccessful,
  setOnSiteParams,
  getOnSiteById,
  getOnSiteByIdSuccessful,
  onSiteDelete,
  isOnSiteViewModel,
  isOnSiteAddModel,
  FilterInputChangeValue,
  InputChangeValue,
  onSiteAdd,
  CheckOnSiteApprove,
  OnSiteApproveCheckSuccessful,
  clearOnsiteList,
  checkFilterStatus,
  apiError,
  isRejectModelVisible,
  rejectModelInputChangeValue,
  isDrawerVisible,
  SelectChangeValue,
  IsAddApprovedModel,
  ApprovedInputChangeValue,
  SelectApprovedChangeValue,
  onSiteApprovedAdd,
  setSelectedRowKeys,
  selectRow,
  OnSiteViewApprove,
  OnSiteViewApproveSuccessful,
  onSiteApprove,
  onSiteApproveSuccessful,
} = onSiteSlice.actions;

export default onSiteSlice.reducer;
