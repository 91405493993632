import React, { useRef, useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Layout, Button, Row, Col, Image, Badge, Switch } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { RiCloseLine, RiMenuFill } from "react-icons/ri";
import { Search } from "react-iconly";
import { HomeOutlined } from "@ant-design/icons";
import HeaderSideMenu from "./HeaderSideMenu";
import HeaderMenu from "./HeaderMenu";
import HeaderUser from "./HeaderUser";
import HeaderNotifications from "./HeaderNotifications";
import WhiteLogo from "../../../assets/images/logo/dec_new_logo.svg";
import { theme } from "../../../store/customise/customiseSlice";

const { Header } = Layout;

function MenuHeader(props) {
  const { setVisible } = props;

  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);

  // Focus
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      // setStatus("Geolocation is not supported by your browser");
    } else {
      // setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // setStatus(null);
          // setLat(position.coords.latitude);
          // setLng(position.coords.longitude);
          localStorage.setItem("latitude", position.coords.latitude);
          localStorage.setItem("longitude", position.coords.longitude);
          console.log("position.coords.latitude", position.coords.latitude);
          console.log("position.coords.longitude", position.coords.longitude);
        },
        () => {
          // setStatus("Unable to retrieve your location");
        }
      );
    }
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);

  const searchClick = () => {
    setSearchHeader(true);

    setTimeout(() => {
      inputFocusRef.current.focus({
        cursor: "start",
      });
    }, 200);
  };

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
    setSearchHeader(false);
  };

  function themeClick(index) {
    if (index == 0) {
      document.querySelector("body").classList.replace("dark", "light");
      localStorage.setItem("theme", "light");
      props.theme("light");
    } else {
      document.querySelector("body").classList.replace("light", "dark");
      localStorage.setItem("theme", "dark");
      props.theme("dark");
    }
  }

  // Children
  const headerChildren = () => {
    return (
      <Row
        className="hp-w-100 hp-position-relative"
        align="middle"
        justify="space-between"
      >
        {/* <Col className="hp-mobile-sidebar-button hp-mr-24">
          <Button
            className="hp-mobile-sidebar-button"
            type="text"
            onClick={showDrawer}
            icon={
              <RiMenuFill
                size={24}
                className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
              />
            }
          />
        </Col> */}

        {/* <Col
          flex="1"
          style={{ display: !searchHeader ? 'none' : 'block' }}
          className={`hp-pr-md-0 hp-pr-16 hp-header-search ${searchActive && "hp-header-search-active"}`}
        >
        </Col> */}

        <Col>
          <Row align="middle">
            <HeaderSideMenu />
            <Col className="hp-d-flex-center  hp-mr-16">
              <Link to="/dashboard">
                <Button
                  type="text"
                  className="hp-mb-16"
                  icon={
                    <div className="hp-position-relative">
                      {/* <Home set="bold" className="hp-text-color-black-60" /> */}
                      {/* <i className={"hp-text-color-dark-60 ri-2x ri-home-3-line"} style={{color: 'white'}} /> */}
                      <HomeOutlined
                        className="hp-text-color-dark-60"
                        style={{ fontSize: "20px", color: "#b2bec3 " }}
                      />
                    </div>
                  }
                />
              </Link>
            </Col>
            {props.quickMenuItemsList?.menuitems?.length != 0 && <HeaderMenu />}
          </Row>
        </Col>
        <Col className="hp-logo-item ">
          <img
            src={WhiteLogo}
            style={{
              height: 60,
              width: 100,
            }}
            alt="Logo"
          />
        </Col>

        <Col>
          <Row align="middle">
            <Col className="hp-d-flex-center hp-mr-4">
              <Switch
                checkedChildren="Light"
                unCheckedChildren="Dark"
                defaultChecked
                onChange={(e) => {
                  themeClick(e ? 0 : 1);
                }}
              />
            </Col>

            <HeaderNotifications />

            <HeaderUser />
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Header>
      <Row justify="center" className="hp-w-100">
        {customise.contentWidth == "full" && (
          <Col span={24}>{headerChildren()}</Col>
        )}

        {customise.contentWidth == "boxed" && (
          <Col xxl={20} xl={22} span={24}>
            {headerChildren()}
          </Col>
        )}
      </Row>
    </Header>
  );
}
function mapStateToProps({ system, customise }) {
  return {
    quickMenuItemsList: system.quickMenuItemsList,
    theme: customise.theme,
  };
}

export default connect(mapStateToProps, { theme })(MenuHeader);
