import { createSlice } from "@reduxjs/toolkit";

export const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    reportsItemsList: [],
    reportDataList: [],
    modelName: "",
    reportParamsData: { file_format: 5 },
    columnsList: [],
    reportView: false,
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        reportsItemsList: [],
        reportParamsData: { file_format: 5 },
        reportDataList: [],
        columnsList: [],
        reportView: false,
      };
    },
    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            reportParamsData: {
              ...state.reportParamsData,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            reportParamsData: {
              ...state.reportParamsData,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            reportParamsData: {
              ...state.reportParamsData,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        default:
          return {
            ...state,
            reportParamsData: {
              ...state.reportParamsData,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   reportParamsData: {
      //     ...state.reportParamsData,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },

    getReportList: (state, action) => {
      return {
        ...state,
        loading: true,
       
        reportDataList: [],
      };
    },
    ReportListSuccessful: (state, action) => {
      var columns = [];
      if (action.payload.length > 0) {
        columns = Object.keys(action.payload[0]);
      }
      return {
        ...state,
        reportDataList: action.payload,
        columnsList: columns,
        loading: false,
        reportView: true,
      };
    },
    loadingStatus: (state, action) => {
      return { ...state, loading: action.payload };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  InputChangeValue,
  getReportList,
  ReportListSuccessful,
  loadingStatus,
  apiError,
} = reportsSlice.actions;

export default reportsSlice.reducer;
