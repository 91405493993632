import { createSlice } from "@reduxjs/toolkit";

export const designationSlice = createSlice({
  name: "designation",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    designationList: [],
    model: false,
    error_msg: null,
    listCount: 0,
    rowdata: {},
    designationParams: {
      no_of_pages: 0,
      
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getDesignation: (state) => {
      if (state.designationParams?.page == 1) {
        return {
          ...state,
          loading: true,
          designationList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    designationListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.designationParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.designationList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.designationParams?.page_size
      );
      return {
        ...state,
        designationList: list,
        listCount: response.count,
        loading: false,
        designationParams: {
          ...state.designationParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetDesignationParams: (state, action) => {
      return {
        ...state,
        designationParams: action.payload,
      };
    },
    designationAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    designationAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    getDesignationByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getDesignationByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
        },
      };
    },
    designationDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getDesignation,
  designationListSuccessful,
  SetDesignationParams,
  designationAdd,
  designationAddSuccessful,
  getDesignationByID,
  getDesignationByIDSuccessful,
  designationDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
} = designationSlice.actions;

export default designationSlice.reducer;
