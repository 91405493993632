import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { RiCloseFill } from "react-icons/ri";
import {
  Button,
  Badge,
  Row,
  Col,
  Dropdown,
  Divider,
  Avatar,
  notification,
} from "antd";
import { Notification, TickSquare, CloseSquare, Danger } from "react-iconly";
import {
  getNotificationList,
  isDrawerVisible,
  notificationActive,
  readNotifition,
  updateNotification,
} from "../../../store/notifications/notificationSlice";
import { Link } from "react-router-dom";
// import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import avatarImg from "../../../assets/images/memoji/memoji-5.png";
import AllNotifications from "./AllNotifications";
import moment from "moment";
// import { IOBaseURL } from "../../../helpers/AxiosHelper";

// import { getEnquiryByID } from "../../../store/sideMenu/Enquiry/enquirySlice";
import { getLeaveById } from "../../../store/sideMenu/leave/leaveSlice";
import { onMessageListener } from "../../../view/firebaseNotifications/firebase";

function HeaderNotifications(props) {
  const close = () => {};
  const history = useHistory();
  const direction = useSelector((state) => state.customise.direction);
  const uuid = uuidv4();
  // const socket = io.connect(IOBaseURL);

  let access_token = localStorage.getItem("access_token");
  let device_uuid = localStorage.getItem("device_uuid");

  // socket.on("notification", (data) => {
  //   console.log("notification", data);
  //   openNotification(data);
  //   props.updateNotification(data);
  // });

  //  Example Logout

  // socket.emit('logout')

  // Login Feedback
  //   socket.on('login_feedback',(msg) => {
  //     console.log("login_feedback", msg);
  // });

  const openNotification = (data) => {
    console.log("Data", data);
    notification.info({
      // message: data.type,
      description: data.message,
      duration: 10,
      closeIcon: (
        <RiCloseFill className="remix-icon hp-text-color-black-80" size={24} />
      ),
    });
  };

  useEffect(() => {
    props.getNotificationList({ page: 1, search: "", page_size: 10 });

    // socket.emit("login", {
    //   access_token: access_token,
    //   device_uuid: device_uuid,
    // });

    onMessageListener()
      .then((payload) => {
        console.log("firebase otification-------------", payload.data);
        openNotification(payload.data);
        props.updateNotification(payload.data);
      })
      .catch((err) => console.log("failed-----------: ", err));
  }, []);

  function nvaigateLink(item) {
    if (
      item.type == "onsite_request" ||
      item.type == "onsite_request_approve"
    ) {
      history.push("/pages/onSite");
    } else if (item.type == "late") {
      history.push("/pages/attendance");
    } else if (
      item.type == "late_request" ||
      item.type == "late_request_approve"
    ) {
      history.push("/pages/lateRequest");
    } else if (item.type == "report_late") {
      history.push("/pages/late");
    } else if (
      item.type == "leave_request" ||
      item.type == "leave_request_approve"
    ) {
      history.push("/pages/leave");
    } else if (
      item.type == "task_created" ||
      item.type == "task_status_created" ||
      item.type == "task_status_updated"
    ) {
      history.push("/pages/task_management");
    } else {
    }
  }

  const notificationMenu = (
    <>
      {props.listCount != 0 ? (
        <div
          className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-pt-24 hp-pb-18 hp-px-18 hp-mt-18"
          style={{ width: 350 }}>
          <Row align="middle" justify="space-between" className="hp-mb-18">
            <Col className="h5 hp-text-color-black-100 hp-text-color-dark-10 hp-text-color-dark-0 hp-mr-64">
              Notifications
            </Col>

            <Col className="hp-bg-color-primary-1 hp-border-radius-full hp-badge-text hp-text-color-black-0 hp-py-4 hp-px-6 hp-ml-24">
              {props.listCount} New
            </Col>
          </Row>

          <Divider className="hp-my-4" />

          <div
            className="hp-overflow-y-auto hp-px-10"
            style={{ maxHeight: 300, marginRight: -10, marginLeft: -10 }}>
            {props.notificationList.map((item, key) => {
              return (
                <>
                  <Link
                    to="#"
                    onClick={() => {
                      nvaigateLink(item);
                      props.readNotifition(item.id);
                    }}
                    className="hp-mb-10 "
                    key={key}>
                    <Row
                      align="middle"
                      className="hp-cursor-pointer hp-border-radius hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-py-8 hp-px-10"
                      style={{ marginLeft: -10, marginRight: -10 }}>
                      <Col span={4} md={4}>
                        <Avatar
                          size={38}
                          icon={
                            <TickSquare
                              size={16}
                              className="hp-text-color-success-1"
                            />
                          }
                          className="hp-d-flex-center-full hp-bg-success-4"
                        />
                      </Col>

                      <Col span={20} md={20}>
                        <span className="hp-d-block hp-w-100 hp-mb-4 hp-font-weight-500 hp-p1-body">
                          {item.subject}
                        </span>
                        <span
                          className="hp-d-block hp-badge-text hp-font-weight-400 
                        hp-text-color-black-60 hp-text-color-dark-40 hp-p2-body">
                          {item.type} -{" "}
                          {moment(
                            item.created_on,
                            "YYYY-MM-DD HH:mm:ss"
                          ).format("DD-MM-YYYY hh:mm:ss a")}
                        </span>
                      </Col>
                    </Row>
                  </Link>
                  <Divider className="hp-my-4" />
                </>
              );
            })}
          </div>

          <Divider className="hp-my-4" />

          <Button
            type="text"
            onClick={() => {
              props.isDrawerVisible(true);
              props.notificationActive(false);
            }}
            block
            className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-fill-primary-1 hp-fill-dark-primary-2 hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-mt-4">
            All notifications
          </Button>

          <AllNotifications />
        </div>
      ) : (
        <div
          className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-pt-24 hp-pb-18 hp-px-18 hp-mt-18"
          style={{ width: 288 }}>
          <h5 className="hp-text-color-primary-1 hp-font-weight-600 hp-text-center">
            No Notifications
          </h5>
        </div>
      )}
    </>
  );

  return (
    <Col className="hp-d-flex-center hp-mr-sm-12 hp-mr-16">
      {/* <Button type="primary" onClick={openNotification}>
    Open the notification box
  </Button> */}
      {/* <Button
       onClick={() =>props.notificationActive(!props.active)}>Hello</Button> */}
      <Button
        type="text"
        // onClick={() =>props.notificationActive(!props.active)}
        icon={
          <Dropdown
            dropdownRender={() => notificationMenu}
            placement="bottomRight"
            onOpenChange={(value) => props.notificationActive(value)}
            open={props.active}
            trigger={["click"]}>
            <div className="hp-position-relative">
              <div
                className="hp-position-absolute"
                style={
                  direction == "rtl"
                    ? { left: -5, top: -5 }
                    : { right: -5, top: -5 }
                }>
                {props.listCount != 0 && <Badge dot status="processing" />}
              </div>

              <Notification set="curved" className="hp-text-color-black-60" />
            </div>
          </Dropdown>
        }
      />
    </Col>
  );
}
function mapStateToProps({ notification, system }) {
  return {
    userAccessList: system.userAccessList,
    listCount: notification.listCount,
    notificationList: notification.notificationList,
    active: notification.active,
  };
}

export default connect(mapStateToProps, {
  getNotificationList,
  isDrawerVisible,
  notificationActive,
  readNotifition,
  updateNotification,
  // getEnquiryByID,
  getLeaveById,
})(HeaderNotifications);
