import { createSlice } from "@reduxjs/toolkit";

export const stateSlice = createSlice({
  name: "state",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    stateslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    stateParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getStates: (state) => {
      if (state.stateParams?.page == 1) {
        return {
          ...state,
          loading: true,
          stateslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stateListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.stateParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.stateslist, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.stateParams?.page_size
      );
      return {
        ...state,
        stateslist: list,
        listCount: response.count,
        loading: false,
        stateParams: {
          ...state.stateParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetStateParams: (state, action) => {
      return {
        ...state,
        stateParams: action.payload,
      };
    },
    stateAdd: (state) => {
      return {
        ...state,
        loading: true
      };

    },
    stateAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    getStateByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getStateByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
        },
      };
    },
    stateDelete: (state) => {
      return {
        ...state,
        loading: false

      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload
      };
    },

  }

});
export const {
  getStates,
  stateListSuccessful,
  stateAdd,
  stateAddSuccessful,
  stateDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetStateParams,
  getStateByID,
  getStateByIDSuccessful,
} = stateSlice.actions;

export default stateSlice.reducer;
