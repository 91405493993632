import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getLeavereasons,
  leavereasonsListSuccessful,
  leavereasonsAddSuccessful,
  isModelVisible,
  leaveReasonsByIdSuccessful,
} from "./leavereasonsSlice";

// AUTH related methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* getLeavereason({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/leave_reasons/",
      params
    );
    yield put(leavereasonsListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* AddLeavereasons({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/masters/leave_reasons/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  Leave Reason Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getLeavereasons({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Leave Reason. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(leavereasonsAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/leave_reasons/" + payload.id + "/",
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  Leave Reason Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getLeavereasons({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Leave Reason. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(leavereasonsAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteLeavereasons({ payload }) {
  var countryID = payload;
  try {
    const response = yield call(
      postDelete,
      "/masters/leave_reasons/" + countryID + "/"
    );

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
                Leave Reason has been deleted Successfully!
            </p>
          </div>
        ),
      });
      yield put(getLeavereasons({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry!   Leave Reason has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* LeaveReasonsById({ payload }) {
  try {
    const response = yield call(
      getList,
      "/masters/leave_reasons/" + payload + "/",
      {}
    );
    yield put(leaveReasonsByIdSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchgetLeavereasons() {
  yield takeEvery("leavereasons/getLeavereasons", getLeavereason);
}
export function* watchLeavereasonsAdd() {
  yield takeEvery("leavereasons/leavereasonsAdd", AddLeavereasons);
}

export function* watchLeavereasonsDelete() {
  yield takeEvery("leavereasons/leavereasonsDelete", DeleteLeavereasons);
}
export function* watchLeaveReasonsById() {
  yield takeEvery("leavereasons/leaveReasonsById", LeaveReasonsById);
}
function* LeavereasonsSaga() {
  yield all([
    fork(watchgetLeavereasons),
    fork(watchLeavereasonsAdd),
    fork(watchLeavereasonsDelete),
    fork(watchLeaveReasonsById),
  ]);
}

export default LeavereasonsSaga;
