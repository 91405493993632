import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  apiError,
  getDesignation,
  designationListSuccessful,
  designationAddSuccessful,
  getDesignationByIDSuccessful,
  isModelVisible,
} from "./designationSlice";

// AUTH related methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* DesignationGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/designations/",
      params
    );
    yield put(designationListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddDesignation({ payload }) {
  try {
    if (payload.obj.id == 0) {
      const response = yield call(
        postAdd,
        "/masters/designations/",
        payload.obj
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Designation Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getDesignation({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Designation. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(designationAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/designations/" + payload.obj.id + "/",
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Designation Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getDesignation({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Designation. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(designationAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteDesignation({ payload }) {
  var departmentID = payload;
  try {
    const response = yield call(
      postDelete,
      "/masters/designations/" + departmentID + "/"
    );
    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Designation has been deleted Successfully!
            </p>
          </div>
        ),
      });
      yield put(getDesignation({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Designation has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DesignationByID({ payload }) {
  try {
    const response = yield call(
      getList,
      "/masters/designations/" + payload + "/",
      {}
    );
    yield put(getDesignationByIDSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetDesignation() {
  yield takeEvery("designation/getDesignation", DesignationGet);
}
export function* watchDesignationAdd() {
  yield takeEvery("designation/designationAdd", AddDesignation);
}
export function* watchDesignationDelete() {
  yield takeEvery("designation/designationDelete", DeleteDesignation);
}
export function* watchGetDesignationByID() {
  yield takeEvery("designation/getDesignationByID", DesignationByID);
}
function* DesignationSaga() {
  yield all([
    fork(watchGetDesignation),
    fork(watchDesignationAdd),
    fork(watchDesignationDelete),
    fork(watchGetDesignationByID),
  ]);
}

export default DesignationSaga;
