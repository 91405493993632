import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const monthlyAttendanceSlice = createSlice({
  name: "MonthAttendance",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    attendanceData: {},
  },
  reducers: {
    InputChangeValue: (state, action) => {
      return {
        ...state,
        attendanceData: {
          ...state.attendanceData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getAttendance: (state, action) => {
      return{
        ...state,
        loading: true
      }
    },
    getAttendanceSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    getAttendanceProgress: (state, action) => {
      return{
        ...state,
        loading: true
      }
    },
    setActionTimeout: (state, action) => {
      return{
        ...state,
        loading: true
      }
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  InputChangeValue,
  getAttendance,
  getAttendanceSuccessful,
  getAttendanceProgress,
  setActionTimeout,
  apiError,
} = monthlyAttendanceSlice.actions;

export default monthlyAttendanceSlice.reducer;
