import React, { useState } from "react";
import { Card, Row, Col, Typography, Table, Tag, Space, Input } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

export default function TaskListCard(props) {
  const columnClass =
    "hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 hp-font-weight-600 ";
  const { Search } = Input;

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      width: 10,
    },
    {
      title: "Code",
      width: 70,
      render: (row) => <span className={columnClass}>{row.code}</span>,
    },
    {
      title: "Date",
      width: 100,
      render: (row) => (
        <span className={columnClass}>
          {moment(row.date).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      title: "Name",
      width: 100,
      render: (row) => (
        <span className={columnClass}>{row.user?.username}</span>
      ),
    },
    {
      title: "Task Subject",
      width: 150,
      render: (row) => <span className={columnClass}>{row.subject}</span>,
    },
    {
      title: "Start Date",
      width: 100,
      dataIndex: "code",
      render: (row) => (
        <span className={columnClass}>
          {moment(row.from_date).format("DD-MM-YYYY")}
        </span>
      ),
    },

    {
      title: "Due Date",
      width: 100,
      render: (row) => (
        <span className={columnClass}>
          {moment(row.to_date).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      title: "Priority",
      width: 100,
      render: (row) => (
        <span className={columnClass}>
          <Tag
            color={
              row.priority_status == 2
                ? "success"
                : row.priority_status == 3
                ? "error"
                : "processing"
            }
          >
            {" "}
            {row.priority_status_name == null
              ? "Pending"
              : row.priority_status_name}
          </Tag>
        </span>
      ),
    },
    {
      title: "Assignee",
      width: 120,
      render: (row) => (
        <span className={columnClass}>
          {row.user?.first_name} {row.user?.last_name}
        </span>
      ),
    },
    {
      title: "Status",
      width: 100,
      render: (row) => (
        <span className={columnClass}>
          <Tag color={row.task_status?.color}> {row.task_status?.name}</Tag>
        </span>
      ),
    },
    {
      title: "Actions",
      key: "action",
      width: 10,
      render: (row, record) => (
        <Space size="middle">
          <Link to="/pages/task_management">
            <i
              className="ri-eye-line hp-mr-2 "
              style={{ cursor: "pointer", color: "#fc6f03" }}
              // onClick={() => {
              //   props.getTaskById({ row, type: "view" });
              // }}
            ></i>
          </Link>
        </Space>
      ),
    },
  ];

  const data = props.data.map((row, key) => ({
    ...row,
    index: key + 1,
  }));

  return (
    <Card className="hp-border-color-black-40 hp-card-6">
      <Row gutter={[12, 12]}>
        {/* <Col span={12} className="hp-mb-10">
          <Search
            placeholder="Search..."
            allowClear
            onChange={(e) => {
              if (e.target.value == "") {
                props.getTaskTemplatesList({
                  ...props.taskTemplatesParams,
                  page: 1,
                  search: "",
                });
                props.SetTaskTemplatesParams({
                  ...props.taskTemplatesParams,
                  page: 1,
                  search: "",
                });
              }
            }}
            onSearch={(value) => {
              onSearch(value);
            }}
          />
        </Col> */}
        <Col span={24} className="hp-mb-6">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ x: 1300 }}
            size="middle"
          />
        </Col>
      </Row>
    </Card>
  );
}
