import { createSlice } from "@reduxjs/toolkit";

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    organizationList: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    organizationParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getOrganization: (state) => {
      if (state.organizationParams?.page == 1) {
        return {
          ...state,
          loading: true,
          organizationList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    organizationListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.organizationParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.organizationList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.organizationParams?.page_size
      );
      return {
        ...state,
        organizationList: list,
        listCount: response.count,
        loading: false,
        organizationParams: {
          ...state.organizationParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetOrganizationParams: (state, action) => {
      return {
        ...state,
        organizationParams: action.payload,
      };
    },
    organizationAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    organizationAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    organizationDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    organizationById: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    organizationByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
        }
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getOrganization,
  organizationListSuccessful,
  SetOrganizationParams,
  organizationAdd,
  organizationAddSuccessful,
  organizationById,
  organizationByIdSuccessful,
  organizationDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
} = organizationSlice.actions;

export default organizationSlice.reducer;
