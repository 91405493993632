import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const shiftSlice = createSlice({
  name: "shifts",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    shiftList: [],
    model: false,
    drawer: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    shiftParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getShifts: (state, action) => {
      if (state.shiftParams?.page == 1) {
        return {
          ...state,
          loading: true,
          shiftList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    shiftListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      // if (state.shiftParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.shiftList, ...response.results];
      // }
      var noofpages = Math.ceil(
        action.payload.count / state.shiftParams?.page_size
      );
      return {
        ...state,
        shiftList: response.results,
        listCount: response.count,
        loading: false,
        shiftParams: {
          ...state.shiftParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetShiftParams: (state, action) => {
      return {
        ...state,
        shiftParams: action.payload,
      };
    },
      clearShiftList:(state, action) => {
        return {
          ...state,
          loading: true,
          shiftList: [],
          shiftParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          },
        }
      },
    shiftAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    
    getShiftByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getShiftByIDSuccessful: (state, action) => {
      var weekIds = [];
      weekIds = action.payload.week_offs.map((week) => {
        return week.id;
      });

      var holidayIds = [];
      holidayIds = action.payload.holidays.map((holiday) => {
        return holiday.id
      });
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
          // id: action.payload.id,
          end_time: moment(action.payload?.end_time, "HH:mm: A"),
          start_time: moment(action.payload?.start_time, "HH:mm: A"),
          late1: moment(action.payload?.late1, "HH:mm: A"),
          late2: moment(action.payload?.late2, "HH:mm: A"),
          late3: moment(action.payload?.late3, "HH:mm: A"),
          grace_time: action.payload.grace_time,
          week_off_ids: weekIds,
          holiday_ids: holidayIds,
          shift_early_end_time: action.payload.shift_early_end_time == null ? moment("12:00 AM", "HH:mm: A") : moment(action.payload?.shift_early_end_time, "HH:mm: A"),
          shift_early_time: action.payload.shift_early_time == null ?  moment("12:00 AM", "HH:mm: A") : moment(action.payload?.shift_early_time, "HH:mm: A"),
          early_enable: action.payload.early_enable,
          late_enable: action.payload.late_enable,
          // week_off_ids:
          //   action.payload.week_offs?.length != 0
          //     ? action.payload.week_offs[0].id
          //     : "",
        },
      };
    },
    shiftDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    InputChangeValue: (state, action) => {
      if(action.payload.key == "start_time"){
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            end_time: null,
            total_time: null,
          },
        };
      }else if(action.payload.key == "late1"){
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            late2: null,
            late3: null,
          },
        };
      }else if(action.payload.key == "late2"){
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            late3: null,
          },
        };
      }else{
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
          },
      }
      }
    
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      }
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getShifts,
  shiftListSuccessful,
  SetShiftParams,
  shiftAdd,
  shiftAddSuccessful,
  getShiftByID,
  getShiftByIDSuccessful,
  shiftDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  isDrawerVisible,
  clearShiftList,
} = shiftSlice.actions;

export default shiftSlice.reducer;
