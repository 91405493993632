import { createSlice } from "@reduxjs/toolkit";
import { e } from "mathjs";
import moment from "moment";

export const taskManagementSlice = createSlice({
  name: "task",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    rowdata: {},
    taskList: [],
    taskData: {},
    task_id: 0,
    taskListObj: {},
    visible: false,
    taskStatusList: [],
    taskParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    taskCommentParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    filterStatus: false,
    commentData: {},
    taskCommentList: [],
    imageLooading: false,
    taskListImg: [],

    addTaskListImg: [],
    taskCommentImg: [],
  },
  reducers: {
    getTaskManagementList: (state, action) => {
      if (state.taskParams?.page == 1) {
        return {
          ...state,
          loading: true,
          // taskId: action.payload.response?.id,
          taskList: [],
        };
      } else {
        return {
          ...state,
          // taskId: action.payload.response?.id,
          loading: true,
        };
      }
    },

    taskManagementListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.taskParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        taskList: action.payload.response.results,
        // taskList: [
        //   ...state.taskList,
        //   ...action.payload.response.results,
        // ],
        loading: false,
        taskParams: {
          ...state.taskParams,
          no_of_pages: noofpages,
        },
      };
    },
    setTaskParams: (state, action) => {
      return {
        ...state,
        taskParams: action.payload,
      };
    },
    getTaskById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getTaskByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        // taskAddModel: true,
        taskData: {
          ...action.payload.response,
          id: action.payload.response?.id,
          from_date: moment(action.payload.response?.from_date),
          to_date: moment(action.payload.response?.to_date),
          user: {
            id: action.payload.response.user?.id,
            name:
              action.payload.response.user?.first_name +
              " " +
              action.payload.response.user?.username,
            first_name: action.payload.response.user?.first_name,
          },
          task_status_id: action.payload.response.task_status?.id,
          is_target: action.payload.response.is_target,
        },
        taskAddModel: action.payload.type == "update" ? true : false,
        taskViewModel: action.payload.type == "view" ? true : false,
      };
    },

    InputChangeValue: (state, action) => {
      // return {
      //     ...state,
      //     taskData: {
      //       ...state.taskData,
      //       [action.payload.key]: action.payload.value,
      //     },
      //   };
      if (action.payload.key == "from_date") {
        return {
          ...state,
          taskData: {
            ...state.taskData,
            [action.payload.key]: action.payload.value,
            to_date: null,
            // no_of_days:
            //   action.payload.value.diff(state.taskData.from_date, "days") + 1,
          },
        };
      } else {
        return {
          ...state,
          taskData: {
            ...state.taskData,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    taskAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    taskDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    clearTaskList: (state, action) => {
      return {
        ...state,
        loading: true,
        taskList: [],
        taskParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        filterStatus: false,
      };
    },

    changeTaskStatus: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    taskStatusVisible: (state, action) => {
      return {
        ...state,
        loading: false,
        visible: action.payload.value,
        task_id: action.payload.task_id,
      };
    },

    isTaskViewModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          taskViewModel: action.payload,
        };
      } else {
        return {
          ...state,
          taskData: {},
          taskViewModel: action.payload,
        };
      }
    },
    isTaskAddModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          taskAddModel: action.payload,
        };
      } else {
        return {
          ...state,
          taskAddModel: action.payload,
          taskData: {},
          addTaskListImg:[],
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        taskParams: {
          ...state.taskParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    getTaskStatus: (state) => {
      return {
        ...state,
        loading: true,
        taskStatusList: [],
      };
    },
    taskStatusSuccessful: (state, action) => {
      var taskListArr = [];
      taskListArr = action.payload.filter((task) => task.default);
      return {
        ...state,
        taskStatusList: action.payload,
        taskData: {
          ...state.taskData,
          task_status_id:
            state.taskData.id == null
              ? taskListArr[0]?.id
              : state.taskData.task_status_id,
        },
        loading: false,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    SelectChangeValue: (state, action) => {
      return {
        ...state,
        taskData: {
          ...state.taskData,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name,
          },
        },
      };
    },
    CommentInputChangeValue: (state, action) => {
      return {
        ...state,
        commentData: {
          ...state.commentData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    commentAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    // commentAddSuccessful: (state, action) => {
    //   return {
    //     ...state,
    //     loading: false,
    //     commentData: [],
    //   };
    // },
    commentDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getTaskByCommentList: (state, action) => {
      if (state.taskCommentParams?.page == 1) {
        return {
          ...state,
          loading: true,
          taskCommentList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    taskByCommentListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.taskParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        taskCommentList: action.payload.results,
        // taskCommentList: [
        //   ...state.taskCommentList,
        //   ...action.payload.response.results,
        // ],
        loading: false,
        taskCommentParams: {
          ...state.taskCommentParams,
          no_of_pages: noofpages,
        },
      };
    },
    clearComments: (state, action) => {
      return {
        ...state,
        loading: true,
        taskCommentList: [],
        commentData: {},
        taskCommentParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        addTaskListImg: [],
        taskCommentImg: [],
      };
    },
    getTaskImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        imageLooading: true,
        taskData: {
          ...state.taskData,
          [action.payload.imageType]: null,
        },
      };
    },
    taskImageUploadSuccessful: (state, action) => {
      var file = action.payload.payload.file;
      var status = action.payload.status;
      var msg = action.payload.msg;
      var selectedImg = state.taskListImg.map((i) => {
        if (i.file.name != file.name) {
          // (i.status = status), (i.msg = msg);
          return {
            ...i,
            status: status,
            msg: msg,
          };
        }
        return i;
      });
      return {
        ...state,
        loading: false,
        imageLooading: false,

        taskListImg: [...selectedImg],
        // taskListImg: action.payload.response.data,
        // taskData: {
        //   ...state.taskData,
        //   [action.payload.ImageType]: action.payload.response.data,
        //   [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        // },
      };
    },
    taskViewImgUpload: (state, action) => {
      var imgList = state.addTaskListImg.map((img) => {
        return {
          ...img,
          status: "uploading",
        };
      });
      return {
        ...state,
        loading: true,
        addTaskListImg: imgList,
      };
    },
    taskViewImgUploadSuccessful: (state, action) => {
      console.log("taskViewImgUploadSuccessful", action.payload);
      // var imgList = state.addTaskListImg.map((img) => {
      //   return {
      //     ...img,
      //     status: uploading,
      //   };
      // });
      return {
        ...state,
        loading: false,
        addTaskListImg: [],
      };
    },
    addImageUpload: (state, action) => {
      return {
        ...state,
        imageLooading: true,
        addTaskListImg: [...state.addTaskListImg, action.payload],
      };
    },
    taskRemoveImg: (state, action) => {
      var selectedImg = [];
      selectedImg = state.addTaskListImg.filter(
        (i) => i.file.uid != action.payload.file.uid
      );
      return {
        ...state,
        addTaskListImg: selectedImg,
      };
    },
    commentImageUpload: (state, action) => {
      return {
        ...state,
        imageLooading: true,
        taskCommentImg: [...state.taskCommentImg, action.payload],
      };
    },
    commentRemoveImg: (state, action) => {
      var commentImg = [];
      commentImg = state.taskCommentImg.filter(
        (i) => i.file.uid != action.payload.file.uid
      );
      return {
        ...state,
        taskCommentImg: commentImg,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getTaskManagementList,
  taskManagementListSuccessful,
  setTaskParams,
  getTaskById,
  getTaskByIdSuccessful,
  InputChangeValue,
  taskAdd,
  taskDelete,
  clearTaskList,
  changeTaskStatus,
  taskStatusVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  isTaskViewModel,
  isTaskAddModel,
  getTaskStatus,
  taskStatusSuccessful,
  checkFilterStatus,
  SelectChangeValue,
  commentAdd,
  CommentInputChangeValue,
  commentDelete,
  getTaskByCommentList,
  taskByCommentListSuccessful,
  clearComments,
  getTaskImageUpload,
  taskImageUploadSuccessful,
  addImageUpload,
  taskRemoveImg,
  commentImageUpload,
  commentRemoveImg,
  taskViewImgUpload,
  taskViewImgUploadSuccessful,

  apiError,
} = taskManagementSlice.actions;

export default taskManagementSlice.reducer;
