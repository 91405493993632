import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import { apiError, getAttendanceSuccessful, getAttendanceProgress, setActionTimeout } from "./monthlyAttendanceSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { message, Modal } from "antd";
import { select } from "redux-saga/effects";
import { evaluate } from "mathjs";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";


const delay = ms => new Promise(resolve => {
  setTimeout(() => {
    // here is where I want to specify my timeout logic
    console.log('timed-out')
    resolve()
  }, ms)
})

 function* ActionTimeout () {
  let timeout
    timeout = yield delay(10000)
    yield put(getAttendanceProgress())
  console.log('post delay')
}


function* AttendanceGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/attendance/monthly_attendance/",
      params
    );
    Modal.success({
      icon: (
        <span className="remix-icon">
          <RiCheckboxCircleLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Monthly attendance is being processed, Please Wait..
          </p>
        </div>
      ),
    });
    yield put(setActionTimeout());
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Gettting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* AttendanceProgress({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/attendance/monthly_attendance/status/",
      params
    );
    if(response.status == true){
      yield put(setActionTimeout())
    }else{
      yield put(getAttendanceSuccessful())
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Monthly attendance processed Successfully!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Gettting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(setActionTimeout())
    // yield put(apiError(error));
  }
}


export function* watchGetAttendance() {
  yield takeEvery("MonthAttendance/getAttendance", AttendanceGet);
}
export function* watchGetAttendanceProgress() {
  yield takeEvery("MonthAttendance/getAttendanceProgress", AttendanceProgress);
}
export function* watchSetActionTimeout() {
  yield takeEvery("MonthAttendance/setActionTimeout", ActionTimeout);
}
function* MonthlyAttendanceSaga() {
  yield all([
    fork(watchGetAttendance),
    fork(watchGetAttendanceProgress),
    fork(watchSetActionTimeout),
  ]);
}

export default MonthlyAttendanceSaga;
