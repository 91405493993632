import { createSlice } from "@reduxjs/toolkit";
import { e } from "mathjs";
import moment from "moment";

export const lateRequestSlice = createSlice({
  name: "lateRequests",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    rowdata: {},
    lateList: [],
    lateReasonModel: false,
    // screen: "",
    leaveAddModel: false,
    //
    lateData: {},
    lateParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    CheckLateApprove: false,
    RejectMode: false,
    lateRejectData: {},
    filterStatus: false,
    ApproveMode: false,
  },
  reducers: {
    getLateList: (state, action) => {
      if (state.lateParams?.page == 1) {
        return {
          ...state,
          loading: true,
          lateList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    LateListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.lateParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        // lateList: [
        //   ...state.lelateListaveList,
        //   ...action.payload.response.results,
        // ],
        lateList: action.payload.response.results,
        loading: false,
        lateParams: {
          ...state.lateParams,
          no_of_pages: noofpages,
        },
      };
    },
    setLateParams: (state, action) => {
      return {
        ...state,
        lateParams: action.payload,
      };
    },
    isLateReasonModel: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          lateReasonModel: action.payload.model,
          lateData: {},
          screen: action.payload.screen,
        };
      } else {
        return {
          ...state,
          lateReasonModel: action.payload.model,
          lateData: {
            clock_in: action.payload.row?.clock_in,
            user: action.payload.row?.user,
            // user_id: action.payload.row.user?.id,
            late_mins: action.payload.row?.late_mins,
            query: action.payload.row.query,
          },
          screen: action.payload.screen,
        };
      }
    },
    InputChangeValue: (state, action) => {
      if (action.payload.key == "to_date") {
        return {
          ...state,
          lateData: {
            ...state.lateData,
            [action.payload.key]: action.payload.value,
            no_of_days:
              action.payload.value.diff(state.lateData.from_date, "days") + 1,
          },
        };
      } else {
        return {
          ...state,
          lateData: {
            ...state.lateData,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },

    addLateRequests: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    getLateById: (state, action) => {
      return {
        ...state,
        loading: true,
        screen: action.payload.screen,
      };
    },

    getLateByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        // lateViewModel: true,
        lateData: {
          ...action.payload.response,
          id: action.payload.response?.id,
          requested_date: moment(action.payload.response?.requested_date),
          from_date: moment(action.payload.response?.from_date),
          to_date: moment(action.payload.response?.to_date),
          late_reason_id: action.payload.response.late_reason?.id,
        },
        lateReasonModel: action.payload.type == "update" ? true : false,
        lateViewModel: action.payload.type == "view" ? true : false,
      };
    },

    CheckLateApprove: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    LateApproveCheckSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        CheckLateApprove: action.payload,
      };
    },
    isLateViewModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          lateViewModel: action.payload,
        };
      } else {
        return {
          ...state,
          lateData: {},
          lateViewModel: action.payload,
        };
      }
    },
    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        lateRejectData: {},
      };
    },
    isApproveModelVisible: (state, action) => {
      return {
        ...state,
        ApproveMode: action.payload,
        lateRejectData: {},
      };
    },
    rejectModelInputChangeValue: (state, action) => {
      return {
        ...state,
        lateRejectData: {
          ...state.lateRejectData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    LateApprove: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    LateApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        lateViewModel: false,
        RejectMode: false,
        ApproveMode: false,
      };
    },

    ///
    leaveDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    isLeaveAddModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          leaveAddModel: action.payload,
        };
      } else {
        return {
          ...state,
          leaveAddModel: action.payload,
          leaveData: {},
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        lateParams: {
          ...state.lateParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    clearLeaveList: (state, action) => {
      return {
        ...state,
        loading: true,
        leaveList: [],
        filterStatus: false,
        lateParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getLateList,
  LateListSuccessful,
  setLateParams,
  isLateReasonModel,
  InputChangeValue,
  addLateRequests,
  getLateById,
  getLateByIdSuccessful,
  CheckLateApprove,
  LateApproveCheckSuccessful,
  isLateViewModel,
  isRejectModelVisible,
  rejectModelInputChangeValue,
  LateApprove,
  LateApproveSuccessful,
  leaveDelete,
  isLeaveAddModel,
  FilterInputChangeValue,
  leaveRequestAdd,
  isDrawerVisible,
  clearLeaveList,
  checkFilterStatus,
  isApproveModelVisible,
  apiError,
} = lateRequestSlice.actions;

export default lateRequestSlice.reducer;
