import { createSlice } from "@reduxjs/toolkit";
import { e } from "mathjs";
import moment from "moment";

export const taskTemplateCreationSlice = createSlice({
  name: "taskTemplateCreation",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    model: false,
    error_msg: null,
    drawer: false,
    listCount: 0,
    taskTemplatesList: [],
    taskTemplatesParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    templateData: {},
    taskAddModel: false,
    rowdata: {
      taskitemsList: [],
    },
    selecTemplateTask: [],
    filterStatus: false,
    // Template task master
    templateTaskParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    templateTasklist: [],
  },
  reducers: {
    getTaskTemplatesList: (state) => {
      if (state.taskTemplatesParams?.page == 1) {
        return {
          ...state,
          loading: true,
          taskTemplatesList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    taskTemplatesListSuccessful: (state, action) => {
      var response = action.payload;
      // var list = [];
      // if (state.taskTemplatesParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.taskTemplatesList, ...response.results];
      // }
      var noofpages = Math.ceil(
        action.payload.count / state.taskTemplatesParams?.page_size
      );
      return {
        ...state,
        taskTemplatesList: response.results,
        listCount: response.count,
        loading: false,
        taskTemplatesParams: {
          ...state.taskTemplatesParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetTaskTemplatesParams: (state, action) => {
      return {
        ...state,
        taskTemplatesParams: action.payload,
      };
    },
    clearTaskTemplates: (state, action) => {
      return {
        ...state,
        loading: false,
        taskTemplatesList: [],
        taskTemplatesParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        templateData: {},
        selecTemplateTask: [],
      };
    },
    getTaskTemplatesByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getTaskTemplatesByIDSuccessful: (state, action) => {
      var template_list = action.payload.template_items.map((task) => {
        return {
          id: task.id,
          subject: task.template_task?.subject,
          description: task.template_task?.description,
          priority_status_name: task.template_task?.priority_status_name,
          repeat_every: task.repeat_every,
          repeat_days: task.repeat_days,
          template_task_id: task.template_task.id,
          start_date: moment(task.start_date),
          due_days: task.due_days,
          dodelete: false,
        };
      });
      return {
        ...state,
        loading: false,
        // model: true,
        templateData: {
          ...action.payload,
          role_id: action.payload.role?.id,
        },
        selecTemplateTask: template_list,
      };
    },

    isTaskAddModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          taskAddModel: action.payload,
        };
      } else {
        return {
          ...state,
          taskAddModel: action.payload,
          templateTasklist: [],
        };
      }
    },

    addTemplateTaskRow: (state, action) => {
      return {
        ...state,
        loading: false,
        selecTemplateTask: [
          ...state.selecTemplateTask,
          {
            subject: action.payload.subject,
            description: action.payload.description,
            priority_status_name: action.payload.priority_status_name,
            repeat_every: null,
            repeat_days: null,
            template_task_id: action.payload.id,
            start_date: "",
            due_days: null,
            dodelete: false,
          },
        ],
        taskAddModel: false,
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        templateData: {
          ...state.templateData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    TaskInputChangeValue: (state, action) => {
      var selectedTask = state.selecTemplateTask.map((item) => {
        if (item.template_task_id == action.payload.item.template_task_id) {
          // item[action.payload.key]= action.payload.value;
          if (action.payload.key == "repeat_every") {
            return {
              ...item,
              [action.payload.key]: action.payload.value,
              repeat_days:
                action.payload.value == 1
                  ? "1"
                  : action.payload.value == 2
                  ? "7"
                  : action.payload.value == 3
                  ? "30"
                  : action.payload.value == 4
                  ? "365"
                  : "",
            };
          } else {
            return {
              ...item,
              [action.payload.key]: action.payload.value,
            };
          }
        }
        return item;
      });
      return {
        ...state,
        selecTemplateTask: selectedTask,
      };
    },

    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          templateData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    addTaskTemplate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    taskTemplateDelete: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    // Template Task Master

    getTemplateTask: (state) => {
      if (state.templateTaskParams?.page == 1) {
        return {
          ...state,
          loading: true,
          templateTasklist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    templateTaskListSuccessful: (state, action) => {
      var response = action.payload;
      var taskList = [];
      var selectedTask_ids = state.selecTemplateTask.map((i) => {
        return i.template_task_id;
      });
      taskList = response.results.filter((item) => {
        return selectedTask_ids.indexOf(item.id) == -1;
      });
      var list = [];
      if (state.templateTaskParams?.page == 1) {
        list = taskList;
      } else {
        list = [...state.templateTasklist, ...taskList];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.templateTaskParams?.page_size
      );
      return {
        ...state,
        templateTasklist: list,
        loading: false,
        taskAddModel: true,
        templateTaskParams: {
          ...state.templateTaskParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetTemplateTaskParams: (state, action) => {
      return {
        ...state,
        templateTaskParams: action.payload,
      };
    },

    removeTask: (state, action) => {
      console.log("action.payload.id", action.payload);
      var selectedTask2 = [];
      if (action.payload.id) {
        selectedTask2 = state.selecTemplateTask.map((item) => {
          if (item.template_task_id == action.payload.template_task_id) {
            return { ...item, dodelete: true };
          }
          return item;
        });
      } else {
        selectedTask2 = state.selecTemplateTask.filter(
          (i) => i.template_task_id != action.payload.template_task_id
        );
      }
      return {
        ...state,
        selecTemplateTask: selectedTask2,
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        taskTemplatesParams: {
          ...state.taskTemplatesParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getTaskTemplatesList,
  taskTemplatesListSuccessful,
  SetTaskTemplatesParams,
  getTaskTemplatesByID,
  getTaskTemplatesByIDSuccessful,
  isTaskAddModelVisible,
  TaskInputChangeValue,
  addTemplateTaskRow,
  removeTask,
  isModelVisible,
  InputChangeValue,
  addTaskTemplate,
  taskTemplateDelete,
  isDrawerVisible,
  FilterInputChangeValue,
  clearTaskTemplates,
  // Template task master
  getTemplateTask,
  templateTaskListSuccessful,
  SetTemplateTaskParams,
  checkFilterStatus,
  apiError,
} = taskTemplateCreationSlice.actions;

export default taskTemplateCreationSlice.reducer;
